<template>
  <div>
    <!-- <el-table stripe :data="mainTable">
      <el-table-column label="Имя объекта" prop="name"></el-table-column>
      <el-table-column label="Отход" prop="ss01name"></el-table-column>
      <el-table-column
        label="Уровень опасности"
        prop="danger_name"
      ></el-table-column>
      <el-table-column label="Движение" prop="actions_name"></el-table-column>
      <el-table-column label="Сумма" prop="sum"></el-table-column>
    </el-table> -->

    <h2 id="h2-sort">Сортировка по отходу</h2>
    <el-button id="sort-button" type="primary" @click="toggleButton()"
      >Сортировать по</el-button
    >
    <div class="report-period">
      <h3>Отчет за период:</h3>
      <p>{{ this.$route.query.StartDate }} - {{ this.$route.query.EndDate }}</p>
    </div>

  <div v-if="!sort">
    <div v-for="elem in sampleArray" :key="elem.code" >
      <div class="report-period">
        <h3>Код отхода:</h3>
        <p>{{ elem.code }} -</p>
        <p>{{ elem.ss01name }}</p>
      </div>
      <div class="report-period">
        <h3>Степень и класс опасности:</h3>
        <p>{{ elem.pss01_danger_degree_name }}</p>
      </div>

      <div v-for="e in elem.dependencyArray" :key="e.id">
        <div class="report-period">
          <h3>Объект:</h3>
          <p>{{ e.name }}</p>
        </div>
        <div class="table-wrapper">
        <div class="table">
          <div class="table-tittle">
            <h4>Действие:</h4>
            <h4>Количество отходов:</h4>
          </div>
          <div class="table-actions-main">
            <div
              class="table-actions"
              v-for="act in e.movement"
              :key="act.action_id"
            >
              <div class="actions">
                <p>{{ act.action_name }}</p>
              </div>
              <div class="actions">
                <p>
                  {{ act.sum + " " + act.wgs }}
                </p>
              </div>
            </div>
            <div class="table-actions">
              <div class="actions">
                {{ " " }}
              </div>
              <div class="actions">
                <p>{{ "Итого: "+ getSUM(e.movement)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

  <div  v-if="sort">
    <div v-for="elem in sampleArray" :key="elem.id">
      <div class="report-period">
          <h3>Объект:</h3>
          <p>{{ elem.name }}</p>
        </div>
      

      <div v-for="e in elem.dependencyArray" :key="e.code">
        <div class="report-period">
        <h3>Код отхода:</h3>
        <p>{{ e.code }} -</p>
        <p>{{ e.ss01name }}</p>
      </div>
      <div class="report-period">
        <h3>Степень и класс опасности:</h3>
        <p>{{ e.pss01_danger_degree_name }}</p>
      </div>
        <div class="table">
          <div class="table-tittle">
            <h4>Действие:</h4>
            <h4>Количество отходов:</h4>
          </div>
          <div class="table-actions-main">
            <div
              class="table-actions"
              v-for="act in e.movement"
              :key="act.action_id"
            >
              <div class="actions">
                <p>{{ act.action_name }}</p>
              </div>
              <div class="actions">
                <p>
                  {{ act.sum + " " + act.wgs}}
                </p>
              </div>
            </div>
            <div class="table-actions">
              <div class="actions">
                {{ " " }}
              </div>
              <div class="actions">
                <p>{{ "Итого: "+ getSUM(e.movement)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- <el-table>
      <el-table-column label="Движение" prop="ReportPeriod"></el-table-column>
      <el-table-column label="Количество отходов" prop="Unit"></el-table-column>
    </el-table> -->
  </div>
</template>
<script>
import { AXIOS } from "@/AXIOS/http-common";
export default {
  data() {
    return {
      sort:false,
      TableOst: [],
      mainTable: [
        {
          id: null,
          action_id: null,
          actions_name: null,
          code: null,
          name: null,
          pss01_danger_degree_id: null,
          sum: null,
          ss01name: null,
          danger_name: null,
        },
      ],
      sampleArray: [
        {
          code: null,

          pss01_danger_degree_id: null,
          pss01_danger_degree_name: null,
          ss01name: null,
          dependencyArray: [
            {
              name: null,
              id: null,
              movement: [
                {
                  action_id: null,
                  action_name: null,
                  sum: null,
                },
              ],
            },
          ],
        },
      ],
      actions: [
        { id: 1, name: "Образовалось" },
        { id: 2, name: "Поступило (внутреннее перемещение)" },
        { id: 3, name: "Передано (внутреннее перемещение)" },
        { id: 4, name: "Обезврежено" },
        { id: 5, name: "Использовано" },
        { id: 6, name: "Захоронено" },
        { id: 7, name: "Прочее" },
        { id: 8, name: "Направлено на хранение" },
        {
          id: 9,
          name: "Фактическое количество отходов, находящихся на хранении",
        },
        { id: 10, name: "Передано контрагенту" },
        { id: 11, name: "Поступило от контрагента / физ.лица"},
      ],
      danger: [
        { id: 1, name: "первый класс" },
        { id: 2, name: "второй класс" },
        { id: 3, name: "третий класс" },
        { id: 4, name: "четвертый класс" },
        { id: 5, name: "неопасные" },
        { id: 6, name: "не определен" },
      ],
    };
  },
  methods: {
    getSUM(acts)
    {
      let S=0;
      acts.forEach(element => {
        S=S + element.sum*(element.action_id==3||element.action_id==4||element.action_id==5||element.action_id==6||element.action_id==7||element.action_id==8||element.action_id==10 ? -1 : 1);
      });
      return (Math.round(S*1000000)/1000000) +" "+ acts[0].wgs;
    },
    groupBySs01() {
      this.TableOst=[];

      this.mainTable.forEach((mainTabl) => {
        let nuls = 0;
        this.TableOst.forEach((TableOs) => {
          if (TableOs.code == mainTabl.code) {
            nuls = 1;
          }
        });
        if (nuls == 0) {
          this.TableOst.push({
            code: mainTabl.code,
            pss01_danger_degree_id: mainTabl.pss01_danger_degree_id,
            pss01_danger_degree_name: this.danger.find(
              (dan) => dan.id == mainTabl.pss01_danger_degree_id
            ).name,
            ss01name: mainTabl.ss01name,
            dependencyArray: [],
          });
        }
      });

      this.TableOst.forEach((TableOs) => {
        this.mainTable.forEach((mainTabl) => {
          if (TableOs.code == mainTabl.code) {
            let nuls = 0;
            TableOs.dependencyArray.forEach((TableOsDA) => {
              if (TableOsDA.id == mainTabl.id) nuls = 1;
            });
            if (nuls == 0) {
              TableOs.dependencyArray.push({
                id: mainTabl.id,
                name: mainTabl.name,
                movement: [],
              });
            }
          }
        });
      });

      this.TableOst.forEach((TableOs) => {
        TableOs.dependencyArray.forEach((TableOsDA) => {
          this.mainTable.forEach((mainTabl) => {
            if (TableOs.code == mainTabl.code && TableOsDA.id == mainTabl.id) {
              let nuls = 0;
              TableOsDA.movement.forEach((TableOsDAMo) => {
                if (TableOsDAMo.action_id == mainTabl.action_id) nuls = 1;
              });
              if (nuls == 0) {
                TableOsDA.movement.push({
                  action_id: (mainTabl.action_id==2 && !mainTabl.rtfo) ? 11 : mainTabl.action_id,
                  sum: Math.round(mainTabl.sum*1000000)/1000000,
                  wgs: mainTabl.wgs,
                  action_name: this.actions.find(
                    (act) => act.id == ((mainTabl.action_id==2 && !mainTabl.rtfo) ? 11 : mainTabl.action_id)
                  ).name,
                });
              }
            }
          });
        });
      });

      this.sampleArray = this.TableOst;
      console.log("this.TableOst");
      console.log(this.sampleArray);
    },
    groupByObject() {

      this.TableOst=[];
      this.mainTable.forEach((mainTabl) => {
        let nuls = 0;
        this.TableOst.forEach((TableOs) => {
          if (TableOs.id == mainTabl.id) {
            nuls = 1;
          }
        });
        if (nuls == 0) {
          this.TableOst.push({
            id: mainTabl.id,
            name: mainTabl.name,
            dependencyArray: [],
          });
        }
      });

      this.TableOst.forEach((TableOs) => {
        this.mainTable.forEach((mainTabl) => {
          if (TableOs.id == mainTabl.id) {
            let nuls = 0;
            TableOs.dependencyArray.forEach((TableOsDA) => {
              if (TableOsDA.code == mainTabl.code) nuls = 1;
            });
            if (nuls == 0) {
              TableOs.dependencyArray.push({
                code: mainTabl.code,
                pss01_danger_degree_id: mainTabl.pss01_danger_degree_id,
                pss01_danger_degree_name: this.danger.find(
                  (dan) => dan.id == mainTabl.pss01_danger_degree_id
                ).name,
                ss01name: mainTabl.ss01name,
                movement: [],
              });
            }
          }
        });
      });

      this.TableOst.forEach((TableOs) => {
        TableOs.dependencyArray.forEach((TableOsDA) => {
          this.mainTable.forEach((mainTabl) => {
            if (TableOsDA.code == mainTabl.code && TableOs.id == mainTabl.id) {
              let nuls = 0;
              TableOsDA.movement.forEach((TableOsDAMo) => {
                if (TableOsDAMo.action_id == mainTabl.action_id) nuls = 1;
              });
              if (nuls == 0) {
                TableOsDA.movement.push({
                  action_id: (mainTabl.action_id==2 && !mainTabl.rtfo) ? 11 : mainTabl.action_id,
                  sum: Math.round(mainTabl.sum*1000000)/1000000,
                  wgs: mainTabl.wgs,
                  action_name: this.actions.find(
                    (act) => act.id == ((mainTabl.action_id==2 && !mainTabl.rtfo) ? 11 : mainTabl.action_id)
                  ).name,
                });
              }
            }
          });
        });
      });

      this.sampleArray = this.TableOst;
      console.log("this.TableOst");
      console.log(this.sampleArray);
    },
    // toogle() {
    //   let button = document.querySelector("#sort-button");
    //   let h2 = document.querySelector("#h2-sort");
    //   button.addEventListener("click", function () {
    //     if ((h2.innerHTML = "Сортировка по отходу")) {
    //       h2.innerHTML = "Сортировка по подразделению";
    //     } else {
    //       h2.innerHTML = "Сортировка по отходу";
    //     }
    //   });
    // },
    toggleButton() {
      var x = document.querySelector("#h2-sort");
      if (x.innerHTML === "Сортировка по отходу") {
        this.groupByObject();
        x.innerHTML = "Сортировка по подразделению";
        this.sort=true;
      } else {
        this.groupBySs01();
        x.innerHTML = "Сортировка по отходу";
        this.sort=false;
      }
    },
  },
  //   async mounted() {
  //     //this.$route.query
  //     await AXIOS.post("report/get/MainTable", this.$route.query).then(
  //       (response) => {
  //         this.mainTable = response.data;
  //         console.log(this.mainTable);
  //         // this.mainTable = this.mainTableEdit;
  //         // console.log(this.mainTableEdit);
  //         this.mainTable.forEach((element) => {
  //           this.actions.forEach((acti) => {
  //             if (acti.id == element.action_id) element.actions_name = acti.name;
  //           });
  //           this.danger.forEach((dag) => {
  //             if (dag.id == element.pss01_danger_degree_id)
  //               element.danger_name = dag.name;
  //           });
  //         });

  //         console.log(this.mainTable);
  //       }
  //     );
  //   },
  async mounted() {
    await AXIOS.post("report/get/MainTable", this.$route.query).then(
      (response) => {
        this.mainTable = response.data;
        this.groupBySs01();

        /*
        console.log(this.mainTable);
        function compareByCode(a, b) {
          return a.code - b.code;
        }
        var res = this.mainTable.sort(compareByCode);
        // console.log(res);
        this.codeSet = new Set();

        for (let elem of res) {
          const codeObj = {};
          codeObj.code = elem.code;
          codeObj.pss01_danger_degree_id = elem.pss01_danger_degree_id;
          codeObj.ss01name = elem.ss01name;
          this.codeSet.add(JSON.stringify(codeObj));
        }
        const list = Array.from(this.codeSet);
        this.sampleArray = [];
        list.forEach((element) => {
          JSON.parse(element);
          this.sampleArray.push(JSON.parse(element));
        });
        this.sampleArray;
        let resultObject = {};

        for (let elem1 of this.sampleArray) {
          let resultArr = [];
          for (let elem2 of this.mainTable) {
            if (
              elem1.code == elem2.code &&
              elem1.ss01name == elem2.ss01name &&
              elem1.pss01_danger_degree_id == elem2.pss01_danger_degree_id
            ) {
              resultObject.action_id = elem2.action_id;
              resultObject.id = elem2.id;
              resultObject.sum = elem2.sum;
              resultObject.name = elem2.name;
              resultArr.push(resultObject);
            }
          }
          elem1.dependencyArray = resultArr;
        }
        console.log(this.sampleArray);*/
      }
    );
  },
};
</script>
<style scoped>
.report-period {
  display: flex;
  align-items: center;
  height: 30px;
}
.report-period p {
  margin-left: 5px;
}
.unit {
  display: flex;
  align-items: center;
}
.unit p {
  margin-left: 5px;
}
.table-wrapper {
  display: flex;
  margin-bottom: 30px;
}
.table {
  background-color: #fafafa;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: 10px;
  width: 70vw;
}
.table-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 5px;
  border-radius: 5px;
  /* background-color: rgb(237, 237, 237); */
  background-color: #ecf5c7;
}
.actions {
  display: flex;
}
.actions p {
  margin-left: 10px;
  margin-right: 10px;
}
.table-tittle {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
}
.table-actions-main {
  padding-bottom: 20px;
}
</style>

